<template>
  <b-table striped hover :items="$store.state.partners" :fields="fields">
    <template v-slot:cell(actions)="data">

      <b-button variant="info" @click="viewDetail(data.item.id)">
        Detail</b-button
      >
    </template>
    <template v-slot:cell(updated)="data">
      {{formatDate(data.item.updatedAt)}}
    </template>
  </b-table>
</template>

<script>
import moment from 'moment';
export default {
  methods: {
    viewDetail(id){
      this.$router.push({ name: 'Mitra', params: { userId: id } });
    },
    formatDate(date) {
      return moment(date).format("ll");
    }
  },
  data() {
    return {
      fields: [
        {
          key: "email",
          label: "Email",
          sortable: false,
        },
        {
          key: "phoneNumber",
          label: "Phone Number",
          sortable: false,
        },
        {
          key: "fullName",
          label: "Nama Lengkap",
          sortable: false,
        },
        {
          key: "instansi",
          label: "Instansi",
          sortable: false,
        },
        {
          key: "updated",
          label: "Update",
          sortable: true,
        },
        {
          key: "role",
          label: "Role",
          sortable: false,
        },
        {
          key: 'actions',
          label: 'Action',
          sortable: false
        }
      ],
    };
  },
};
</script>

<style>
</style>