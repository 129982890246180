var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('button',{staticClass:"btn btn-primary m-3",on:{"click":function($event){return _vm.SetSubscription(_vm.$store.state.userDetail.UserId)}}},[_vm._v(" Set Akhir Langganan ")]),_c('qrcode-vue',{attrs:{"value":_vm.$store.state.userDetail.UserId,"size":_vm.size,"level":"H"}}),_c('ul',_vm._l((Object.entries(
        _vm.$store.state.userDetail
      ).map(function (ref) {
            var key = ref[0];
            var value = ref[1];

            return ({ key: key, value: value });
})),function(detail){return _c('li',{key:detail.key},[_vm._v(" "+_vm._s(detail.key)+" : "+_vm._s(detail.value)+" ")])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }