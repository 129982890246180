var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$store.state.userDetail.role)?_c('div',[(_vm.$store.state.userDetail.role.includes('anonymous'))?_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.approve()}}},[_vm._v(" Approve ")]):_vm._e()]):_vm._e(),_c('ul',_vm._l((Object.entries(
        _vm.$store.state.userDetail
      ).map(function (ref) {
            var key = ref[0];
            var value = ref[1];

            return ({ key: key, value: value });
})),function(detail){return _c('li',{key:detail.key},[_vm._v(" "+_vm._s(detail.key)+" : "+_vm._s(detail.value)+" ")])}),0),_c('img',{attrs:{"src":_vm.$store.state.userDetail.identityPhotoUrl,"alt":""}})])}
var staticRenderFns = []

export { render, staticRenderFns }