<template>
  <div>
    <button
      @click="SetSubscription($store.state.userDetail.UserId)"
      class="btn btn-primary m-3"
    >
      Set Akhir Langganan
    </button>
    <qrcode-vue
      :value="$store.state.userDetail.UserId"
      :size="size"
      level="H"
    ></qrcode-vue>
    <ul>
      <li
        v-for="detail in Object.entries(
          $store.state.userDetail
        ).map(([key, value]) => ({ key, value }))"
        :key="detail.key"
      >
        {{ detail.key }} : {{ detail.value }}
      </li>
    </ul>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
export default {
  components: {
    QrcodeVue,
  },
  data() {
    return {
      size: 300,
    };
  },
  methods: {
    SetSubscription(id) {
      this.$router.push({ name: "SetSubscription", params: { userId: id } });
    },
  },
  mounted() {
    this.$store.dispatch("fetchUser", { userId: this.$route.params.userId });
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
}
</style>