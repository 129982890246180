<template>
  <div>
    <div v-if="$store.state.userDetail.role">
      <button
        v-if="$store.state.userDetail.role.includes('anonymous')"
        @click="approve()"
        class="btn btn-primary"
      >
        Approve
      </button>
    </div>
    <ul>
      <li
        v-for="detail in Object.entries(
          $store.state.userDetail
        ).map(([key, value]) => ({ key, value }))"
        :key="detail.key"
      >
        {{ detail.key }} : {{ detail.value }}
      </li>
    </ul>
    <img :src="$store.state.userDetail.identityPhotoUrl" alt="" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch("fetchPartner", { userId: this.$route.params.userId });
  },
  methods: {
    approve() {
      this.$store
        .dispatch("approveMTPS", { userId: this.$store.state.userDetail.id })
        .then(() => {
          this.$store.dispatch("fetchPartners");
          return this.$store.dispatch("fetchPartner", {
            userId: this.$route.params.userId,
          });
        })
        .catch((err) => {
          alert(err.msg);
        });
    },
  },
};
</script>

<style>
ul {
  list-style-type: none;
}
</style>